/** @define footcontact;  weak */
.footcontact {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  padding: 50px 15px;
  color: #ffffff;
  background-color: #f59a00;
}
@media only screen and (min-width: 1300px) {
  .footcontact {
    /*background: linear-gradient(to bottom, rgba(1,1,1,1) 0%, rgba(1,1,1,1) 39%, rgba(246,41,12,1) 39%, rgba(255,255,255,1) 39%, rgba(255,255,255,1) 41%, rgba(255,255,255,1) 100%);*/
  }
}
.footcontact__contact {
  font-size: 18px;
}
.footcontact__contact svg {
  width: 50px;
  height: 50px;
  margin-right: 20px;
}
.footcontact__item {
  margin: 10px;
}
@media only screen and (min-width: 600px) {
  .footcontact__item {
    margin: 10px 20px;
  }
}
@media only screen and (min-width: 800px) {
  .footcontact__item {
    font-size: 20px;
  }
  .footcontact__item br {
    display: none;
  }
}
.footcontact__item a {
  color: #ffffff;
}
.footcontact__item--full {
  width: 100%;
}
/** @define footer;  weak */
.footer {
  background-color: #ffffff;
}
@media only screen and (min-width: 1300px) {
  .footer {
    background: linear-gradient(to bottom, #090c00 0%, #090c00 39%, #f6290c 39%, #ffffff 39%, #ffffff 41%, #ffffff 100%);
  }
}
.footer__copywrite {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  padding: 30px 15px;
  background: url("../../../images/footer_bcg.webp") no-repeat top center;
}
.footer__copywrite p {
  margin: 5px 0;
}
.no-webp {
  /* postcss-bem-linter: ignore */
}
.no-webp .footer__copywrite {
  background: url("../../../images/footer_bcg.jpg") no-repeat top center;
}
.footer__map {
  max-width: 1300px;
  margin: 0 auto;
}
/*# sourceMappingURL=css/footer.css.map */